.main-navigation__menu-btn {
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2px;
  background: white;
}

.main-navigation__title {
  color: rgb(8, 82, 24);
}

.main-navigation__title a {
  text-decoration: none;
  color: rgb(25, 185, 94);
}

.main-navigation__header-nav {
  display: none;
  margin-right: 2%;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}